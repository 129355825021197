<template>
  <div>
    <h1>Загрузка работой</h1>
    <div class="filter" style="display:flex">
      <div class="filter--field" :class="[zone.label, {'is-active': zone.check == true}]"
           v-for="(zone, index) in filter_zone" :key="index">
        <input :id="zone.label" type="checkbox" @change="chgFilter"  v-model="zone.check">
        <label :for="zone.label">
          <i v-if="zone.check == true" class="fa fa-check" aria-hidden="true"></i>
          <span>{{zone.name}}</span>
        </label>
      </div>
    </div>

    <div v-if="chartData1.length > 1" class="tabs">
      <div class="wrp-tabs--btn">
        <a class="tabs--btn" :class="{'is-active': variant.val == tabs.selected}"
           v-for="(variant, index) in tabs.variants" :key="index"
           @click="selectTab(variant.val)" href="javascript:void(0)">{{variant.title}}</a>
      </div>
      <div class="tabs-content">
        <div v-if="tabs.selected == 1">
          <div class="table">
            <div class="table-head">
              <div class="table-th flex-2">Операция</div>
              <div class="table-th">Часов в день</div>
              <div class="table-th">Часов загрузки</div>
              <div class="table-th">Дней загрузки</div>
            </div>
            <div class="table-body">
              <div class="table-tr" :class="{'bg2': index%2 == 0, 'bg1': index%2 != 0}"
                   v-for="(item, index) in items" :key="index">
                <div class="table-td flex-2">{{item.name}}
                  <i v-if="item.custom_flag == 1" class="fa fa-object-group" aria-hidden="true"></i>
                </div>
                <div class="table-td">
                  <input @change="chgHours(index)" v-model="item.work_day" />
                </div>
                <div class="table-td hours-load"
                     @click.stop="getOperationsSections(item)" >{{item.all_times}}</div>
                <div class="table-td">{{item.day_works}}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tabs.selected == 2">
          <GChart type="ColumnChart" :data="chartData1" :options="chartOptions1" />
        </div>
        <div v-if="tabs.selected == 3">
          <GChart type="ColumnChart" :data="chartData2" :options="chartOptions2" />
        </div>
      </div>
    </div>
    <div v-else class="alert-danger">Нет заказов в выбранных зонах</div>

    <transition name="modal">
      <Modal v-if="showModal" @close="showModal = false">
        <template v-slot:header>
          <h3>Подробно по часам сектора: {{ModalLabel}}</h3>
          <div class="zone-info">
            <template v-for="(zone, index) in filter_zone" :key="index">
              <div class="zone-info--item" v-if="zone.check == true" :class="zone.label"></div>
            </template>
          </div>
        </template>
        <template v-slot:body>
          <div class="custom-table-green">
            <div class="head-wrp " style="display:flex">
              <div class="table-head-green" style="flex:1">Заказ</div>
              <div class="table-head-green" style="flex:3">Операция</div>
              <div class="table-head-green" style="flex:1">Время (минуты)</div>
            </div>
            <div v-for="(item, index) in list_orders_operations" :key="index" >
              <div class="table-body-tr" @click="toggleOperations(index)" :class="item.color">
                <div class="table-body-td" style="flex:1">{{item.sort}}.{{item.number}}</div>
                <div class="table-body-td pointer" style="flex:3;" v-if="item.show === false">
                  ({{item.operations.length}})
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div class="table-body-td pointer" style="flex:3" v-else>
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </div>
                <div class="table-body-td" style="flex:1">{{item.time}}</div>
              </div>
              <tamplate v-if="item.show">
                <div v-for="(oper,index2) in item.operations" :key="index2" class="table-body-tr">
                  <div class="table-body-td" style="flex:1"></div>
                  <div class="table-body-td" style="flex:3">{{oper.label}}</div>
                  <div class="table-body-td" style="flex:1">{{oper.time}}</div>
                </div>
              </tamplate>
            </div>
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import { GChart } from 'vue3-googl-chart';
import Modal from '../components/help/Modal.vue';

export default {
  components: {
    Modal, GChart,
  },
  name: 'RangeWorkOperation',
  data() {
    return {
      labelID: 0,
      showModal: false,
      ModalLabel: '',
      loader: false,
      items: [],
      list_orders_operations: [],
      tabs: {
        selected: 1,
        variants: [
          {
            title: 'Таблица',
            val: 1,
          },
          {
            title: 'График (по дням)',
            val: 2,
          },
          {
            title: 'График (по часам)',
            val: 3,
          },
        ],
      },
      filter_zone: [
        {
          name: 'черная зона',
          label: 'black',
          check: true,
        },
        {
          name: 'красная зона',
          label: 'red',
          check: true,
        },
        {
          name: 'жёлтая зона',
          label: 'yellow',
          check: true,
        },
        {
          name: 'зеленая зона',
          label: 'green',
          check: true,
        },
      ],
      chartData1: [
        ['Year', 'Sales', 'Expenses', 'Profit'],
      ],
      chartOptions1: {
        backgroundColor: 'transparent',
        height: 600,
        chartArea: {
          width: '95%',
          height: '100%',
          bottom: 300,
          top: 50,
        },
        is3D: true,
        legend: {
          position: 'top',
        },
        hAxis: {
          // title: 'Years',
          //  direction: -1,
          slantedText: true,
          slantedTextAngle: 90,
          viewWindowMode: 'explicit',
          viewWindow: {
            min: 0,
          },
        },
        annotations: {
          alwaysOutside: true,
        },
        chart: {
          title: 'Операции(по дням)',
          subtitle: 'Текущее состояние',
        },
      },
      chartData2: [
        ['Year', 'Sales', 'Expenses', 'Profit'],
      ],
      chartOptions2: {
        backgroundColor: 'transparent',
        height: 600,
        chartArea: {
          width: '95%',
          height: '100%',
          bottom: 300,
          top: 50,
        },
        is3D: true,
        legend: {
          position: 'top',
        },
        hAxis: {
          // title: 'Years',
          //  direction: -1,
          slantedText: true,
          slantedTextAngle: 90,
          viewWindowMode: 'explicit',
          viewWindow: {
            min: 0,
          },
        },
        chart: {
          title: 'Операции(по часам)',
          subtitle: 'Текущее состояние',
        },
      },
      error: [],
    };
  },
  created() {
    this.getOperations();
  },
  methods: {
    getOperations() {
      const str = '/api/range-work-operation';
      axios.get(str, {
        params: {
          filter_zone: this.filter_zone,
        },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.items = res.data.operations;
            // this.filter_zone
            this.chartData1 = res.data.chartData1;
            this.chartData2 = res.data.chartData2;
          }
        })
        .catch((e) => {
          this.error.push(e);
        });
    },
    chgHours(index) {
      axios.post('api/save-worktime', {
        custom: this.items[index].custom_flag,
        val: this.items[index].id,
        worktime: this.items[index].work_day,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.getOperations();
          }
          this.loader = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    chgFilter() {
      this.getOperations();
    },
    selectTab(index) {
      this.tabs.selected = index;
    },
    getOperationsSections(item) {
      this.showModal = true;
      this.ModalLabel = item.name;
      this.labelID = item.id;
      const str = '/api/range-work-operation-for-detail';
      axios.get(str, {
        params: {
          filter_zone: this.filter_zone,
          val: item.id,
        },
      }).then((res) => {
        if (res.data.success === 1) {
          this.list_orders_operations = res.data.operations;
        }
      }).catch((e) => {
        this.error.push(e);
      });
    },
    toggleOperations(index) {
      if (this.list_orders_operations[index].show === true) {
        this.list_orders_operations[index].show = false;
      } else {
        this.list_orders_operations[index].show = true;
      }
    },
  },
};
</script>

<style scoped>
  .table{width:60%;}
  .table-head{display:flex; background:#3e5977; color:#fff}
  .table-th, .table-td{flex:1; padding:2px 10px;}
  .table-tr{display: flex;}
  .table-td input{width:100%; height: auto; padding: 0px 8px;}

  .flex-2{flex:3}

  .bg1,.bg2{color:#fff;}
  .bg1{background:#5b7898;}
  .bg2{background:#7191b4;}

  .filter--field{ opacity:0.5;}
  .filter--field.is-active{opacity:1;}
  .filter--field input{display:none;}
  .filter--field label{padding:4px; padding:4px 12px; display:inline-block; cursor:pointer;}
  .filter--field label i {padding-right:4px;}

  .filter--field.black{background:#14171a; color:#fff; }
  .filter--field.red{background:#e33838; color:#fff;}
  .filter--field.yellow{background:#f2f251; color:#000;}
  .filter--field.green{background:#2e842e; color:#fff;}

  .tabs--btn{display:inline-block; background:#ddd; padding:8px 12px;}
  .tabs--btn.is-active{background: #3e5977; color: #fff}

  .alert-danger{
    background: #eed9d9;
    color: #9b1010;
    padding: 8px;
  }

  .hours-load{cursor:pointer}
  .hours-load:hover{opacity:0.5}
  .zone-info--item{width:20px; height:20px; display:inline-block;
    border-radius:20px; margin-right:6px}
  .black{background:#14171a; color:#fff; }
  .red{background:#e33838; color:#fff;}
  .yellow{background:#f2f251; color:#000;}
  .green{background:#2e842e; color:#fff;}

  .table-head-green{background: #a2ec78b3; padding: 6px 12px;}
  .table-body-td{padding: 6px 12px; cursor:default}
  .table-body-td.error{color:red}
  .table-body-tr{display:flex; border-bottom: 1px solid #ddd; }
  .custom-table-green{height: calc(100vh - 120px); overflow-y: auto; padding-right: 15px;}

  .pointer{ cursor:pointer;}

  @media (max-width: 1000px) {
    .table{width:90%;}
  }

  @media (max-width: 980px) {
    .table {
      width: 100%;
    }
  }
</style>
